import { useEffect, useState } from 'react';
import { Col, message, Modal, ModalProps, Row, Segmented, Table, Tag } from 'antd';
import { taxonomyState } from 'recoil/selectors';
import { CategoryPrime, CategoryRoot, CategorySecond, SupplierProductRow } from 'sb/models/Product';
import { useRecoilValue } from 'recoil';
import { SegmentedValue } from 'antd/es/segmented';
import './ProductCategoriesModal.scss';
import { Collapse } from 'react-collapse';
import { updateProductsCategories } from 'sb/api/products';

interface Props extends Omit<ModalProps, 'onOk'> {
  onOk: () => Promise<void>;
  selectedProducts: SupplierProductRow[];
}

export const ProductCategoriesModal: React.FC<Props> = ({ onOk, selectedProducts, ...rest }) => {
  // LOCAL STATE
  const [saving, setSaving] = useState(false);
  const [segmentedOptions, setSegmentedOptions] = useState<any[]>([]);
  const [rootValue, setRootValue] = useState<undefined | SegmentedValue>(undefined);
  const [categoriesPrime, setCategoriesPrime] = useState<CategoryPrime[]>([]);
  const [categoriesSecond, setCategoriesSecond] = useState<CategorySecond[]>([]);
  const [selectedCategoriesPrimary, setSelectedCategoriesPrimary] = useState<CategoryPrime[]>([]);
  const [selectedCategoriesSecond, setSelectedCategoriesSecond] = useState<CategorySecond[]>([]);

  // RECOIL STATE
  const taxonomy: CategoryRoot[] = useRecoilValue(taxonomyState);

  useEffect(() => {
    const segmentedOptions = taxonomy.map((root) => {
      return {
        value: root.id,
        label: root.name
      };
    });
    setSegmentedOptions(segmentedOptions);
    setRootValue(segmentedOptions[0]?.value);
  }, [taxonomy]);

  useEffect(() => {
    if (rest.open) {
      if (segmentedOptions.length > 0 && taxonomy.length > 0) {
        setRootValue(taxonomy[0].id);
        setCategoriesPrime(taxonomy[0].category_prime);
      } else {
        setCategoriesPrime([]);
      }
      setCategoriesSecond([]);
      setSelectedCategoriesSecond([]);
      setSelectedCategoriesPrimary([]);
    }
  }, [rest.open, segmentedOptions.length, taxonomy]);

  const handlePrimaryRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: CategoryPrime[]) => {
      setCategoriesSecond(selectedRows[0].category_second);
      setSelectedCategoriesPrimary(selectedRows);
    }
  };

  const handleSecondaryRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: CategorySecond[]) => {
      setSelectedCategoriesSecond(selectedRows);
    }
  };

  const handleOk = async () => {
    const productIds: number[] = selectedProducts.map((item) => item.id);
    const categoryIds: number[] = selectedCategoriesSecond.map((item) => item.id);
    try {
      setSaving(true);
      await updateProductsCategories(productIds, categoryIds);
      message.success(`${selectedProducts.length} products updated!`);
    } finally {
      setSaving(false);
    }
    setSelectedCategoriesSecond([]);
    setSelectedCategoriesPrimary([]);
    await onOk();
  };

  useEffect(() => {
    if (!rootValue) return;
    const [primaryCategories] = taxonomy.filter((item) => rootValue === item.id);
    setCategoriesPrime(primaryCategories.category_prime);
  }, [rootValue, taxonomy]);

  const handleRootCategoryChange = (value: SegmentedValue) => {
    setRootValue(value);
    setCategoriesSecond([]);
    setSelectedCategoriesSecond([]);
  };

  const handleOnCategoryPrimeRow = (record: CategoryPrime): { onClick: () => void } => {
    return {
      onClick: () => {
        setSelectedCategoriesPrimary([record]);
        const [prime] = categoriesPrime.filter((item) => record.id === item.id);
        setCategoriesSecond(prime.category_second);
        setSelectedCategoriesSecond([]);
      }
    };
  };

  const handleOnCategorySecondRowClick = (record: CategorySecond): { onClick: () => void } => {
    return {
      onClick: () => {
        if (selectedCategoriesSecond.findIndex((item) => item.id === record.id) > -1) {
          setSelectedCategoriesSecond(selectedCategoriesSecond.filter((item) => item.id !== record.id));
        } else {
          setSelectedCategoriesSecond([...selectedCategoriesSecond, record]);
        }
      }
    };
  };

  const onSelectedCategoryTagCloseHandler = (rec: CategorySecond) => {
    setSelectedCategoriesSecond(selectedCategoriesSecond.filter((item) => rec.id !== item.id));
  };

  return (
    <Modal
      title="Update Categories"
      maskClosable={false}
      destroyOnClose
      confirmLoading={saving}
      {...rest}
      onOk={handleOk}
      className={'product_categories-modal'}
    >
      <div className={'text-sm font-bold mb-2'}>Selected Products</div>
      {selectedProducts.map((item) => (
        <Tag color={'#d9d9d9'} className={'tag'} key={item.id}>
          {item.name}
        </Tag>
      ))}
      <div className={'text-sm font-bold mb-2 mt-7'}>Selected Base Category</div>
      <Segmented
        style={{ marginBottom: 24 }}
        options={segmentedOptions}
        onResize={undefined}
        onResizeCapture={undefined}
        block
        onChange={handleRootCategoryChange}
      />
      <Row gutter={24}>
        <Col span={12}>
          <div className={'table-wrapper'}>
            <Table
              className={'line-items'}
              showHeader={false}
              style={{ overflowY: 'scroll' }}
              rowKey={'id'}
              size={'small'}
              scroll={{ y: 300 }}
              dataSource={categoriesPrime}
              onRow={handleOnCategoryPrimeRow}
              rowSelection={{
                type: 'radio',
                selectedRowKeys: selectedCategoriesPrimary.map((item) => item.id),
                ...handlePrimaryRowSelection
              }}
              columns={[
                {
                  title: 'Primary Category',
                  dataIndex: 'name',
                  render: (_, rec: CategoryPrime) => <div className={'cursor-pointer'}>{rec.name}</div>
                }
              ]}
              pagination={false}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className={'table-wrapper'}>
            <Table
              onRow={handleOnCategorySecondRowClick}
              className={'line-items'}
              bordered={false}
              showHeader={false}
              style={{ overflowY: 'scroll' }}
              rowKey={'id'}
              size={'small'}
              scroll={{ y: 300 }}
              dataSource={categoriesSecond}
              rowSelection={{
                selectedRowKeys: selectedCategoriesSecond.map((item) => item.id),
                ...handleSecondaryRowSelection
              }}
              columns={[
                {
                  title: 'Secondary Category',
                  dataIndex: 'name',
                  render: (_, rec: CategorySecond) => <div className={'cursor-pointer'}>{rec.name}</div>
                }
              ]}
              pagination={false}
            />
          </div>
          <div className={'text-xs mt-1 text-[#777]'}>Leave blank to remove all product categories</div>
        </Col>
      </Row>
      <Collapse isOpened={selectedCategoriesSecond.length > 0}>
        <div className={'mt-8'}>
          <div className={'text-sm font-bold mb-2'}>
            Selected Categories: {selectedCategoriesPrimary.length > 0 ? selectedCategoriesPrimary[0].name : ''}
          </div>
          {selectedCategoriesSecond.map((rec) => (
            <Tag
              onClose={() => onSelectedCategoryTagCloseHandler(rec)}
              color={'#d9d9d9'}
              className={'tag'}
              closable
              key={rec.id}
            >
              {rec.name}
            </Tag>
          ))}
        </div>
      </Collapse>
    </Modal>
  );
};
