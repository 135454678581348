import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { DebundleDataType } from 'types/global';
import { DerivedPricingBody, UnitsOfMeasure } from 'sb/models/Contract';
import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const { VITE_APP_UNSECURE_ROUTE } = import.meta.env;

export async function getContract(id?: number) {
  try {
    const res = await axios.get(`/secure/contract/${id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractsBySalesforceDealId(salesforceDealId: string) {
  try {
    const res = await axios.get(`/secure/contract?salesforce_deal_id=${salesforceDealId}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createContract(data: any) {
  try {
    const res = await axios.post(`/secure/contract`, data);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateContract(id: number, data: any) {
  try {
    const res = await axios.put(`/secure/contract/${id}`, data);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function deleteContract(id: number) {
  try {
    const res = await axios.delete(`/secure/contract/${id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateContractMetadata(id: number, data: any) {
  try {
    const res = await axios.put(`/secure/contract/${id}/metadata`, data);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractDealContext(id: number) {
  try {
    const res = await axios.get(`/secure/contract/${id}/context`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateContractDealContext(id: number, values: any) {
  try {
    const res = await axios.post(`/secure/contract/${id}/context`, values);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const getContractAcodisTransactions = async (contract_id: number) => {
  const url = `${VITE_APP_UNSECURE_ROUTE}/ai/contract/${contract_id}/transactions`;

  try {
    const res = await axios.get(url);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export async function updateContractTransactionDefaultListPrice(id: number, data: any) {
  try {
    const res = await axios.put(`/secure/contract_transaction/${id}/default_list_price`, data);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractSections(id: number) {
  try {
    const res = await axios.get(`/secure/contract/${id}/sections`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createContractSection(id: number, values: any) {
  try {
    const res = await axios.post(`/secure/contract/${id}/section`, values);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function insertContractSection(
  id: number,
  body: { name: string; include_in_total?: boolean }
): Promise<any> {
  try {
    const res = await axios.post(`/api/contract/${id}/section`, body);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function deleteContractSection(id: number, sectionId: number) {
  try {
    const res = await axios.delete(`/secure/contract/${id}/section/${sectionId}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Removes a contract section by its identifier.
 *
 * @param {number} id - The unique identifier of the contract section to be removed.
 * @return {Promise<any>} A promise that resolves to the response data after the contract section is successfully removed.
 */
export async function removeContractSection(id: number): Promise<any> {
  try {
    const res = await axios.delete(`/api/contract_section/${id}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createContractSectionFromSpreadSheet(id: number, values: any) {
  try {
    const res = await axios.post(`/secure/contract/${id}/_section`, values);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateContractSection(id: number, sectionId: number, values: any) {
  try {
    const res = await axios.put(`/secure/contract/${id}/section/${sectionId}/line_items`, values);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractComps(id: string, years: number) {
  try {
    const res = await axios.get(`/secure/contract/${id}/comps?years=${years}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractTasks(contract_id: number) {
  try {
    const res = await axios.get(`/api/contract/${contract_id}/tasks`);
    return res?.data?.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractSection(sectionId: number, pageNumber: number, pageSize: number) {
  try {
    const res = await axios.get(
      `/secure/contract/section/${sectionId}/products?page_number=${pageNumber}&page_size=${pageSize}`
    );
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractSectionPrices(sectionId: number) {
  try {
    const res = await axios.get(`/secure/contract/section/${sectionId}/list_prices`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractSectionExportData(id: number, sectionId: number) {
  try {
    const res = await axios.get(`/secure/contract/${id}/section/${sectionId}/export`, {
      responseType: 'blob'
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractExportData(id: number) {
  try {
    const res = await axios.get(`/secure/contract/${id}/export`, {
      responseType: 'blob'
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateContractSectionName(id: number, sectionId: number, name: string) {
  try {
    const res = await axios.put(`/secure/contract/${id}/section/${sectionId}/name`, { section_name: name });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createContractSectionRecord(id: number, sectionId: number, vendorId: number, rec: any) {
  try {
    const res = await axios.post(`/secure/contract/${id}/section/${sectionId}/line_item`, {
      rec,
      vendor_id: vendorId
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getContractSectionRecord(id: number, sectionId: number, transactionId: number) {
  try {
    const res = await axios.get(`/secure/contract/${id}/section/${sectionId}/line_item/${transactionId}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateContractSectionRecord(
  id: number,
  sectionId: number,
  vendorId: number,
  transactionId: number,
  rec: any
) {
  try {
    const res = await axios.put(`/secure/contract/${id}/section/${sectionId}/line_item/${transactionId}`, {
      rec,
      vendor_id: vendorId
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function deleteContractSectionRecord(id: number, sectionId: number, transactionId: number) {
  try {
    const res = await axios.delete(`/secure/contract/${id}/section/${sectionId}/line_item/${transactionId}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function deleteContractSectionRecords(id: number, sectionId: number, transactionIds: number[]) {
  try {
    const res = await axios.post(`/secure/contract/${id}/section/${sectionId}/line_items`, { ids: transactionIds });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function processContractSection(data: any) {
  try {
    const res = await axios.post(`/secure/contract/section/process`, data);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Returns a Prisma structured JSON object
 *
 * @param {number} contract_id
 */
export async function fetchContract(contract_id: number) {
  try {
    const res = await axios.get(`/secure/p/contract/${contract_id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Download Salesforce Content Document
 */
export async function downloadSalesforceFile(ContentDocumentId: string) {
  try {
    const res = await axios.get(`/secure/sf/document/${ContentDocumentId}/view`, { responseType: 'blob' });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch Content Documents linked to a Salesforce Deal
 *
 * @param salesforce_deal_id
 */
export async function fetchSalesforceDealDocuments(salesforce_deal_id: string) {
  try {
    const res = await axios.get(`/secure/sf/deal/${salesforce_deal_id}/doc_links`);
    if (res.data?.status === 'success') {
      return res.data;
    }
    throw new Error(res.data?.message || 'Failed to fetch Salesforce deal documents');
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Set contract_transaction.default_list_price to null
 *
 * @param transaction_id
 */
export const clearTransactionDefaultListPrice = async (transaction_id: number) => {
  try {
    const res = await axios.put(`/secure/contract/transaction/${transaction_id}/clearDefaultListPrice`);
    if (res.data?.status === 'error') {
      return Promise.reject(Error(res.data?.message));
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Clone Contract Section
 *
 * @param section_id section id
 * @param name name of cloned section
 * @returns cloned section
 */
export const cloneContractSection = async (section_id: number, name: string) => {
  try {
    const res = await axios.post(`/secure/contract/section/clone`, { section_id, name });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Combine Contract Sections
 *
 * @param section_ids section id array
 * @param name name of new section
 * @returns combined section
 */
export const combineContractSections = async (section_ids: number[], name: string) => {
  try {
    const res = await axios.post(`/secure/contract/section/combine`, { section_ids, name });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Apply Unit Quantity and Unit of Measure to selected line items
 *
 * @param section_id
 * @param data
 */
export const applyUnitQuantityToSection = async (section_id: number, data: any) => {
  try {
    const res = await axios.patch(`/secure/contract/section/${section_id}/unit_quantity`, { data });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Debundle line items in a bundled BOM
 *
 * @param contract_id
 * @param { DebundleDataType } data
 * @param selectedRows
 */
export const debundleBOM = async (contract_id: number, data: DebundleDataType, selectedRows: any[]) => {
  try {
    const res = await axios.post(`/secure/contract/bom/debundle`, {
      selectedRows: selectedRows,
      contract_id: contract_id,
      section_id: data.section_id,
      section_name: data.section_name,
      section_total: data.section_total
    });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Create transaction note
 *
 * @param transaction_id create transaction id
 * @param note note
 * @returns
 */
export const createTransactionNote = async (transaction_id: number, note: string) => {
  try {
    const res = await axios.post(`/secure/contract/transaction/${transaction_id}/note`, { note });
    if (res.data?.status === 'error') {
      return Promise.reject(Error(res.data?.message));
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get transaction notes
 *
 * @param transaction_id contract transaction id
 * @returns
 */
export const getTransactionNotes = async (transaction_id: number) => {
  try {
    const res = await axios.get(`/secure/contract/transaction/${transaction_id}/notes`);
    if (res.data?.status === 'error') {
      throw new Error(res.data?.message);
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Update transaction best_in_class
 *
 * @param transaction_id contract transaction id
 * @param best_in_class
 * @returns
 */
export const updateTransactionBestInClass = async (transaction_id: number, best_in_class: boolean) => {
  try {
    const res = await axios.put(`/secure/contract/transaction/${transaction_id}/best-in-class`, { best_in_class });
    if (res.data?.status === 'error') {
      throw new Error(res.data?.message);
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 *
 * @param contract_id
 * @param compId
 * @returns
 */
export const getContractProductOverlap = async (contract_id: number, compId: number) => {
  try {
    const url = `/secure/contract/${contract_id}/product_overlap/${compId}`;
    const results = await axios.get(url);

    return results.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch contract summary
 *
 * @param contract_id
 */
export const fetchDealContacts = async (contract_id: number) => {
  try {
    const res = await axios.get(`/secure/contract/${contract_id}/contacts`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    throw new Error(res.data.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Update contract SKUs with matching list prices from selected price list
 *
 * @param contract_id
 * @param price_list_id
 */
export const updateContractSkusPriceList = async (contract_id: number, price_list_id: number) => {
  try {
    const res = await axios.put(`/secure/contract/${contract_id}/price_list`, { price_list_id });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    throw new Error(res.data.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch dashboard contracts
 *
 * @param auth0UserId
 * @param dateEnteredTimeframe
 * @param renewalTimeframe
 */
export const fetchDashboardContracts = async (
  auth0UserId: string | undefined,
  dateEnteredTimeframe?: string,
  renewalTimeframe?: string
) => {
  try {
    const config = {
      method: 'GET',
      url: '/secure/contracts/dashboard'
    };
    if (auth0UserId)
      Object.assign(config, {
        params: {
          analystId: auth0UserId,
          dateEnteredTimeframe: dateEnteredTimeframe,
          renewalTimeframe: renewalTimeframe
        }
      });
    const res = await axios(config);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    throw new Error(res.data.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Select Inquiry
 *
 */
export async function updateTransactionTargets(data: any[]) {
  try {
    const res = await axios.patch(`/secure/contract/targets`, { data });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update Derived pricing for Skues
 *
 * @param data
 */
export async function updateSkusDerivedPricing(data: DerivedPricingBody) {
  try {
    const res = await axios.post(`/secure/contract/skus/derived`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Select Deal
 *
 */
export async function getDeal(id: number) {
  try {
    const res = await axios.get(`/secure/deal/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Get contract BOM
 * @param id
 */
export async function getContractBom(id: number) {
  try {
    const res = await axios.get(`/api/contract/${id}/bom`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Updates the contract transactions with the provided data.
 *
 * @param {Array<any>} body - An array containing the contract transaction data to be patched.
 * @return {Promise<any>} A promise that resolves to the updated contract transaction data if the patch is successful, or rejects with an error message if unsuccessful.
 */
export async function patchContractTransactions(body: Array<any>): Promise<any> {
  try {
    const res = await axios.patch(`/api/contract_transactions`, body);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetches the sections of a contract by making an API call to the specified endpoint using the contract ID.
 * The function attempts to retrieve the sections data for a given contract from the server.
 *
 * @param {number} id - The unique identifier of the contract whose sections are to be fetched.
 * @return {Promise<any>} A promise that resolves to the sections data of the contract if the API call is successful,
 * or rejects with an error message if the call fails or if the server returns a non-success status.
 */
export async function fetchContractSections(id: number): Promise<any> {
  try {
    const res = await axios.get(`/api/contract/${id}/sections`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetches a contract section by its unique identifier.
 *
 * @param {number} id - The unique identifier of the contract section to fetch.
 * @return {Promise<any>} A promise that resolves to the data of the contract section if successful,
 * or rejects with an error message if the fetch fails.
 */
export async function fetchContractSection(id: number): Promise<any> {
  try {
    const res = await axios.get(`/api/contract_section/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Updates a specific contract section with the provided data.
 *
 * @param {number} id - The unique identifier of the contract section to update.
 * @param {{ name: string, include_in_total?: boolean }} body - The data to update the contract section with. Includes the section name and optionally whether it should be included in the total.
 * @return {Promise<any>} A promise resolving to the updated contract section data if successful, or rejecting with an error message.
 */
export async function patchContractSection(
  id: number,
  body: { name: string; include_in_total?: boolean }
): Promise<any> {
  try {
    const res = await axios.patch(`/api/contract_section/${id}`, body);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Posts contract transactions to the server.
 *
 * @param {Array<any>} body - The list of contract transactions to be posted.
 * @return {Promise<any>} - A promise that resolves with the server response data if the post is successful, or rejects with an error message if the post fails.
 */
export async function postContractTransactions(body: Array<any>): Promise<any> {
  try {
    const res = await axios.post(`/api/contract_transactions`, body);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Deletes contract transactions based on the provided IDs.
 *
 * @param {Array<number>} ids - An array of transaction IDs to be deleted.
 * @return {Promise<any>} A promise that resolves with the data of the deleted transactions if successful, or rejects with an error message.
 */
export async function deleteContractTransactions(ids: Array<number>): Promise<any> {
  try {
    const res = await axios.delete(`/api/contract_transactions`, { data: ids });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function patchDealFMVTargets(id: number, body: Array<{ id: number; target_unit_cost_fmv: number }>) {
  try {
    const res = await axios.patch(`/api/deal/${id}/fmv_targets`, body);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Updates the target unit cost FMV and target discount FMV of a contract transaction.
 *
 * @async
 * @param {number} id - The identifier of the contract transaction to update.
 * @param {Object} data - The data containing the new target unit cost FMV and target discount FMV.
 * @param {number | null} data.target_unit_cost_fmv - The new target unit cost FMV value.
 * @param {number | null} data.target_discount_fmv - The new target discount FMV value.
 * @returns {Promise<any>} A promise that resolves to the updated contract transaction data, or rejects with an error.
 */
export async function patchContractTransactionFmvTarget(
  id: number,
  data: { target_unit_cost_fmv: number | null; target_discount_fmv: number | null }
): Promise<any> {
  try {
    const res = await axios.patch(`/secure/contract_transaction/${id}`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const useDocLinks = (salesforce_deal_id: string, config: Record<string, any> = {}) => {
  return useQuery({
    queryKey: ['doc_links', salesforce_deal_id],
    queryFn: async () => await fetchSalesforceDealDocuments(salesforce_deal_id),
    staleTime: 60 * 60 * 24,
    ...config
  });
};

export const useSalesforceFiles = (contentDocumentIds: string[], config: Record<string, any> = {}) => {
  return useQueries({
    queries: (contentDocumentIds || [])?.map((id) => ({
      queryKey: ['sf_document', id],
      queryFn: async () => await downloadSalesforceFile(id),
      staleTime: 60 * 60 * 24,
      ...config
    }))
  });
};

const getUnitsOfMeasure = async (): Promise<UnitsOfMeasure[]> => {
  try {
    const res = await axios.get(`/api/units_of_measure/lov`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const useUnitsOfMeasure = (config: Record<string, any> = {}): UseQueryResult<UnitsOfMeasure[], AxiosError> => {
  return useQuery({
    queryKey: ['units_of_measure'],
    queryFn: async () => await getUnitsOfMeasure(),
    staleTime: 60 * 60 * 24 * 7,
    ...config
  });
};
