import { File } from './Files';

export interface Deal {
  id: number;
  salesforce_deal_id: string | null;
  salesforce_deal_name: string | null;
  name: string | null;
  contract_term: string;
  total_value: number | null;
  contract_start_date: Date | null;
  contract_renewal_date: Date | null;
  projected_start_date: Date | null;
  projected_close_date: Date | null;
  is_enterprise_agreement: boolean;
  subject: string;
  sf_description: string;
  client: Client;
  vendor: Vendor;
  reseller?: Reseller;
  contract_section: any[];
  sections: Section[];
  sfDeal?: SfDeal;
  sfAccount?: SalesforceAccount;
  file: File[];
  status: string;
  creation_date: Date | null;
}

export interface Vendor {
  id: number;
  name: string;
  logo_filename: string;
  logo_url: string;
  _count?: Count;
}

export interface Client {
  id: number;
  name: string;
  are_custom_fields_enabled: boolean;
  custom_field: CustomFieldElement[];
  logo_filename: string;
  logo_url: string;
}

export interface CustomFieldElement {
  options: DefaultOptionType[] | undefined;
  type: string;
  value: any;
  id: number;
  label: string;
  key: string;
  data_type: string;
  attributes: any;
  custom_field_value: CustomFieldValue[];
}

export interface CustomFieldValue {
  id: number;
  val: string;
}

export interface Reseller {
  id: number;
  name: string;
}

export interface Count {
  supplier_deal_dynamic: number;
  supplier_category: number;
}

export interface Section {
  id: number;
  name: string;
  db_row_number: number;
  effective_date: null;
  expiration_date: null;
  profile_id: null;
  section_number: number;
  section_type: null;
  product_count: number;
  total_license_cost: number;
  total_maintenance_cost: null;
}

export interface SfDeal {
  vendor_id: number;
  attributes: Attributes;
  Id: string;
  Name: string;
  Client__r: ClientR;
  OwnerId: string;
  CreatedDate: string;
  Feedback_Date__c: Date;
  Related_Contract__r: ClientR;
  Client_Contact__r: ClientContactR | null;
  Primary_Vendor_not_clickable__c: string;
  Primary_Deal_Vendor__c: string;
  FMV_Report_Link__c: null;
  Formal_PDF_FMV_Delivered__c: string;
  Initial_Quote_Full_Contract_Term__c: number | null;
  NPI_Savings_Target_Full_Contract_Term__c: number;
  Savings_Achieved_Year_1__c: number;
  Savings_Achieved_Full_Contract_Term__c: number;
  Final_Amount_Full_Contract_Term__c: number;
  Calculated_Savings__c: number;
  Start_Date__c: Date;
  Contract_Start_Date__c: Date;
  Contract_Renewal_Date__c: Date;
  Deal_Status__c: string;
  Deal_Reason__c: string;
  Subject__c: string;
  Term_Number__c: number;
  Term__c: string | null;
  LastModifiedDate: string;
  Account_Manager__r: User | null;
  NPI_Analyst__r: User | null;
  Account_Owner__c: string;
  NPI_Savings_Target__c: number;
  reportType: DealRecordType;
}

export interface SalesforceAccount {
  attributes: Attributes;
  Id: string;
  Name: string;
  OwnerId: string;
  Owner: User;
}

export interface User {
  attributes: Attributes;
  Id: string;
  Name: string;
  FirstName: string;
  LastName: string;
  Phone: null | string;
  MobilePhone: string;
}

export interface Attributes {
  type: string;
  url: string;
}

export interface ClientContactR extends User {
  Email: string;
}

export interface ClientR {
  attributes: Attributes;
  Id: string;
  Name: string;
  Owner?: Owner;
}

export interface Owner {
  attributes: Attributes;
  Full_Name__c: string;
  FirstName: string;
  LastName: string;
  Phone: null;
  MobilePhone: string;
}

export enum DealType {
  Renewal = 1,
  'Add-On Purchase' = 2,
  'New Purchase' = 0
}

export enum DealRecordType {
  'Quick Check' = 'quickCheck',
  'Full Report' = 'fullReport'
}

export enum DealRecordTypeRev {
  quickCheck = 'Quick Check',
  fullReport = 'Full Report'
}

export interface SfUserMetaData {
  contract_start_date: string | Date;
  contract_renewal_date: string | Date;
  client_contact_id?: string;
  subject: string;
}

export interface DealNote {
  parentPrivacy: boolean;
  id: number;
  note: string;
  creation_date: string;
  is_released: boolean;
  user: User;
  replies: Reply[];
  is_visible_to_npi: boolean;
}

export interface DealNoteUser {
  name: string;
  user_id: string;
  family_name: string;
  given_name: string;
}

export interface DealNoteReply {
  id: number;
  note: string;
  creation_date: string;
  user: DealNoteReplyUser;
}

export interface DealNoteReplyUser {
  name: string;
  user_id: string;
  family_name: string;
  given_name: string;
}

export interface DealSection {
  id: number;
  name: string;
}

export interface Reseller {
  id: number;
  name: string;
}

export interface Attributes {
  type: string;
  url: string;
}

export interface DealBulkUploadMatch {
  id: number;
  name: string;
  score: number;
}

export interface DealBulkUploadParseData {
  contract_name: string;
  description: string;
  provided_supplier_name: string;
  provided_reseller_name: string | undefined;
  start_date: string;
  end_date: string;
  cost_term: string;
  cost: number;
  supplier_matches?: DealBulkUploadSupplierMatch[];
  reseller_matches?: DealBulkUploadResellerMatch[];
}

export interface VendorFormData {
  label: string;
  value: number;
  disabled?: boolean;
  key?: number;
  title?: string;
}
export interface ResellerFormData {
  label: string;
  value: number;
  disabled?: boolean;
  key?: number;
  title?: string;
}
export interface MultiDealFormData {
  formId?: string;
  contractType?: number;
  name: string;
  description: string;
  vendor: VendorFormData | null;
  reseller: ResellerFormData | null;
  contract_dates: Moment[] | null;
  term: string;
  total_value: number | null;
  supplier_matches?: DealBulkUploadSupplierMatch[];
  reseller_matches?: DealBulkUploadResellerMatch[];
  hasSupplierWarning?: boolean;
  hasResellerWarning?: boolean;
  showPopover?: boolean;
}
export interface MultiDealRequestPayload {
  deal_type: 'renewal';
  name: string;
  description: string;
  vendor_id: number | undefined;
  vendor_name: string;
  reseller_id: number | undefined;
  reseller_name: string | undefined;
  contract_start_date: string;
  contract_renewal_date: string;
  term: string;
  total_value: number;
}

export interface ContractVendor {
  id: number;
  name: string;
}

export interface FollowContractNotification {
  id: string;
  day?: number;
  frequency: string;
  contract_id: number;
  notification_type: string;
}

export interface FollowContractRecord {
  id: number;
  name: string;
  salesforce_deal_id: string;
  contract_renewal_date: string;
  vendor: ContractVendor;
  isMyDeal: boolean;
  notification: FollowContractNotification;
}

export interface UploadDealDocumentResponse {
  id: string;
  success: boolean;
}
