import { Avatar, Col, Divider, Image, Row, Segmented, Space, Tooltip } from 'antd';
import { QuantumVendorDetails, SupplierTitle } from 'models/Supplier';
import fallbackLogo from 'assets/fallbackLogo.png';
import './SupplierCardTitle.scss';
import { CalendarOutlined, InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { screenSizeState, selectedSupplierDynamicsVendorState } from 'recoil/atoms';
import { ACLCheck } from 'shared/Helpers';
import { CLEARBIT_LOGO_BASE_URL } from 'shared/constants';

interface Props {
  supplier?: SupplierTitle;
  onSupplierUpdate?: (supplier: SupplierTitle) => void;
  quantumSupplier?: QuantumVendorDetails | undefined;
  viewInfo?: { showPartnerInfo: boolean; showNPIInfo: boolean; showToggle: boolean };
  setToggleValue?: ((value: ((prevState: string) => string) | string) => void) | undefined;
  toggleValue?: string;
}

const gray = '#ECECEC';
const green = '#57de35';
const orange = '#ffb54c';
const red = '#ff5722';

const SupplierCardTitle: React.FC<Props> = ({
  supplier,
  quantumSupplier,
  viewInfo,
  setToggleValue,
  toggleValue
}: Props) => {
  // LOCAL STATE
  const [fiscalYearColor, setFiscalYearColor] = useState(gray);
  const [days, setDays] = useState<number | undefined>(undefined);
  const [screen, setScreen] = useState({
    logoSpan: 2,
    logoMaxHeight: 64,
    supplierSpan: 11,
    supplierFontSize: 24,
    categoryFontSize: 16,
    fiscalYearSpan: 4,
    fiscalYearAvatarSize: 48,
    fiscalYearAvatarFontSize: 32,
    fiscalYearFontSize: 16,
    fiscalYearSpans: { left: 8, right: 16 },
    inquirySpan: 4,
    inquiryIconFontSize: 22,
    inquiryFontSize: 16
  });

  // RECOIL STATE
  // const setInquiryModalOpen = useSetRecoilState(inquirySubmissionOpenState);
  const screenSize = useRecoilValue(screenSizeState);
  const selectedSupplier = useRecoilValue(selectedSupplierDynamicsVendorState);

  useEffect(() => {
    if (!supplier?.fiscal_year_end) {
      setDays(undefined);
      setFiscalYearColor(gray);
      return;
    }
    const currYear = moment().format('YYYY');
    let days;
    if (moment() >= moment(supplier?.fiscal_year_end + '-' + currYear, 'MM-DD-YYYY', true)) {
      const nextYear = moment().add(1, 'year').format('YYYY');
      days = moment(supplier?.fiscal_year_end + '-' + nextYear, 'MM-DD-YYYY', true).diff(moment(), 'days');
    } else {
      days = moment(supplier?.fiscal_year_end + '-' + currYear, 'MM-DD-YYYY', true).diff(moment(), 'days');
    }
    if (days <= 90) {
      setFiscalYearColor(red);
    } else if (days > 90 && days <= 120) {
      setFiscalYearColor(orange);
    } else {
      setFiscalYearColor(green);
    }
    setDays(days);
  }, [supplier?.fiscal_year_end]);

  useEffect(() => {
    switch (screenSize) {
      case 6:
        setScreen({
          logoSpan: 2,
          logoMaxHeight: 64,
          supplierSpan: ACLCheck(['read:own_inquiry', 'read:company_inquiry', 'read:site_wide_admin']) ? 11 : 15,
          supplierFontSize: 24,
          categoryFontSize: 16,
          fiscalYearSpan: 4,
          fiscalYearAvatarSize: 48,
          fiscalYearAvatarFontSize: 32,
          fiscalYearFontSize: 16,
          fiscalYearSpans: { left: 8, right: 16 },
          inquirySpan: 4,
          inquiryIconFontSize: 22,
          inquiryFontSize: 16
        });
        break;
      case 5:
        setScreen({
          logoSpan: 3,
          logoMaxHeight: 64,
          supplierSpan: ACLCheck(['read:own_inquiry', 'read:company_inquiry', 'read:site_wide_admin']) ? 10 : 14,
          supplierFontSize: 24,
          categoryFontSize: 16,
          fiscalYearSpan: 4,
          fiscalYearAvatarSize: 48,
          fiscalYearAvatarFontSize: 32,
          fiscalYearFontSize: 16,
          fiscalYearSpans: { left: 8, right: 16 },
          inquirySpan: 4,
          inquiryIconFontSize: 22,
          inquiryFontSize: 16
        });
        break;
      case 4:
        setScreen({
          logoSpan: 3,
          logoMaxHeight: 64,
          supplierSpan: ACLCheck(['read:own_inquiry', 'read:company_inquiry', 'read:site_wide_admin']) ? 8 : 5,
          supplierFontSize: 21,
          categoryFontSize: 15,
          fiscalYearSpan: 5,
          fiscalYearAvatarSize: 44,
          fiscalYearAvatarFontSize: 29,
          fiscalYearFontSize: 15,
          fiscalYearSpans: { left: 8, right: 16 },
          inquirySpan: 5,
          inquiryIconFontSize: 29,
          inquiryFontSize: 14
        });
        break;
      case 3:
        setScreen({
          logoSpan: 3,
          logoMaxHeight: 64,
          supplierSpan: ACLCheck(['read:own_inquiry', 'read:company_inquiry', 'read:site_wide_admin']) ? 7 : 13,
          supplierFontSize: 18,
          categoryFontSize: 14,
          fiscalYearSpan: 5,
          fiscalYearAvatarSize: 44,
          fiscalYearAvatarFontSize: 29,
          fiscalYearFontSize: 15,
          fiscalYearSpans: { left: 8, right: 16 },
          inquirySpan: 6,
          inquiryIconFontSize: 29,
          inquiryFontSize: 14
        });
        break;
      case 2:
      case 1:
        setScreen({
          logoSpan: 24,
          logoMaxHeight: 64,
          supplierSpan: 24,
          supplierFontSize: 18,
          categoryFontSize: 13,
          fiscalYearSpan: 24,
          fiscalYearAvatarSize: 44,
          fiscalYearAvatarFontSize: 29,
          fiscalYearFontSize: 15,
          fiscalYearSpans: { left: 4, right: 20 },
          inquirySpan: 24,
          inquiryIconFontSize: 29,
          inquiryFontSize: 14
        });
        break;
    }
  }, [screenSize]);

  const VerticalDivider = (
    <Col className="mx-6">
      <Divider className={'vertical-divider'} type={'vertical'} />{' '}
    </Col>
  );

  return (
    <div className={'supplier-card-title'}>
      <Row>
        <Col className={'self-center text-center'}>
          <span style={{ fontSize: 13 }}>
            <Image
              style={{ maxHeight: 48 }}
              src={
                quantumSupplier
                  ? CLEARBIT_LOGO_BASE_URL + '/' + quantumSupplier?.vendor_domain
                  : supplier?.domain
                  ? CLEARBIT_LOGO_BASE_URL + '/' + supplier?.domain
                  : fallbackLogo
              }
              fallback={fallbackLogo}
              preview={false}
            />
          </span>
        </Col>
        {(screenSize > 2 && VerticalDivider) || <Divider />}
        <Col className={'flex flex-col flex-grow place-content-center'}>
          <div style={{ fontSize: screen.supplierFontSize }} className={'vendor-name'}>
            {quantumSupplier ? quantumSupplier?.name : supplier?.name}
          </div>
          {selectedSupplier?.supplier_category.length === 1 && (
            <div style={{ fontSize: screen.categoryFontSize }} className={'category-name'}>
              {selectedSupplier?.supplier_category[0].category}
            </div>
          )}
        </Col>
        {!viewInfo?.showToggle && viewInfo?.showPartnerInfo && (
            <div
            className="flex items-center space-x-2 px-4 rounded-full bg-gray-200"
            >
            <InfoCircleFilled className="text-gray-700 text-lg" />
            <span className="text-sm text-gray-700">Quantum Info</span>
            </div>
        )}
        {((!viewInfo?.showToggle && viewInfo?.showNPIInfo) || toggleValue == 'NPI Data') && (
          <Col>
            <Row>
              <Col>
                <Avatar
                  className="mr-4"
                  shape="square"
                  size={screen.fiscalYearAvatarSize}
                  style={{
                    background: fiscalYearColor,
                    fontSize: screen.fiscalYearAvatarFontSize
                  }}
                >
                  <CalendarOutlined />
                </Avatar>
              </Col>
              <Col className="pr-6">
                <div className={'score-text'} style={{ fontSize: screen.inquiryFontSize }}>
                  <div className={'title'}>Fiscal Year End</div>
                  <div className={'score'}>
                    {supplier?.fiscal_year_end
                      ? moment(supplier?.fiscal_year_end, 'MM-DD', true).format('MMM Do')
                      : '--'}
                    {days && <span style={{ fontSize: 12, marginLeft: 8 }}>{`(${days} days)`}</span>}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        )}
        {viewInfo?.showToggle && (
          <>
            {toggleValue == 'NPI Data' && ((screenSize > 2 && VerticalDivider) || <Divider />)}
            <Space size={'large'}>
              <div style={{ width: 200 }}>
                <div className="text-sm">Switch View</div>
                <Segmented
                  className="custom-segmented"
                  onChange={(value) => setToggleValue && setToggleValue(value.toString())}
                  options={['NPI Data', 'Partner Info']}
                  value={toggleValue}
                />
              </div>
            </Space>
          </>
        )}
      </Row>
    </div>
  );
};

export default SupplierCardTitle;
